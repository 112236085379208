import { useCallback } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { Button, Type } from '@/components/common'
import { Props as ButtonProps } from '@/components/common/Button/Button'
import useAuth from '@/services/hooks/useAuth'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { Campaign } from '@/services/api/campaign'
import FollowButton from './FollowButton'
import useABTest from '@/services/hooks/useABTest'
import useSiteAssets from '@/services/hooks/useSiteAssets'

export type Props = ButtonProps & {
  campaign: Campaign
  sectionName: 'body' | 'sidebar' | 'hero'
  color: 'oxide' | 'copper'
}

const InvestButton = ({ campaign, sectionName, ...rest }: Props) => {
  const { slug, status, isBeyondBuffer } = campaign
  const router = useRouter()
  const { user, openModal, startAuth } = useAuth()
  const [decision] = useABTest('ellis_island_oauth2')
  const { isExecutingSharePriceChange } = useSiteAssets()

  const ctaText =
    status === 'testing_the_waters' ? 'Express Interest' : 'Back This Project'

  const handleAnalytics = useCallback(() => {
    SegmentHandler.track('On Tap', {
      project: slug,
      location: router.asPath,
      section_name: sectionName,
      element_name: 'cta',
      value: ctaText,
      action_type: 'tap',
    })
    SegmentHandler.track(
      'Click Campaign CTA',
      { ctaText, project: slug },
      { suppressSegmentTrack: true }
    )
  }, [slug, router, ctaText, sectionName])

  const handleClick = useCallback(() => {
    const redirect =
      status === 'testing_the_waters'
        ? `/express-interest/${slug}`
        : `/invest/${slug}`

    if (user) {
      if (!user.isValidEmail && decision.variationKey === 'on') {
        openModal('eiVerifyAccount', redirect)
      } else {
        router.push(redirect)
      }
    } else {
      if (decision.variationKey === 'on') {
        startAuth(redirect)
      } else {
        openModal('verifyAccount', redirect)
      }
    }

    handleAnalytics()
  }, [
    decision.variationKey,
    handleAnalytics,
    openModal,
    router,
    slug,
    startAuth,
    status,
    user,
  ])

  if (
    (status === 'currently_funding' || status === 'testing_the_waters') &&
    !isBeyondBuffer &&
    !isExecutingSharePriceChange
  ) {
    return (
      <Button onClick={handleClick} data-cy="project-cta-button" {...rest}>
        {ctaText}
      </Button>
    )
  }

  return (
    <>
      <FollowButton
        campaign={campaign}
        data-cy="closed-offering-button"
        onClickCallback={handleAnalytics}
        {...rest}
      />

      {isBeyondBuffer && (
        <Type
          as="p"
          variant="caption-sm"
          className={classNames('mt-2 text-center', {
            'text-white': sectionName === 'hero',
          })}
        >
          This offering has reached its maximum and can no longer accept
          investments. In the event that a current investor cancels, space may
          open back up before the close date.
        </Type>
      )}

      {isExecutingSharePriceChange && (
        <Type
          as="p"
          variant="caption-sm"
          className={classNames('mt-2 text-center', {
            'text-white': sectionName === 'hero',
          })}
        >
          We are updating the share price of this offering.
          <br />
          Check back later.
        </Type>
      )}
    </>
  )
}

export default InvestButton

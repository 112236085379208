import classNames from 'classnames'
import React, { CSSProperties, LegacyRef } from 'react'
import ProgressBar from './InvestComponents/ProgressBar'
import AmountRaised from './InvestComponents/AmountRaised'
import Backers from './InvestComponents/Backers'
import TimeRemaining from './InvestComponents/TimeRemaining'
import MinInvestment from './InvestComponents/MinInvestment'
import Buffer from './InvestComponents/Buffer'
import PriorRaise from './InvestComponents/PriorRaise/PriorRaise'
import InvestButton from './InvestComponents/InvestButton'
import useCampaign from '@/services/hooks/useCampaign'
import InvestmentDetails from './InvestComponents/InvestmentDetails'
import { ISectionAnticipatedRegulationTypeFields } from 'src/types/generated/contentful'
import { Campaign } from '@/services/api/campaign'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import CampaignDisclaimer from './InvestComponents/CampaignDisclaimer'
import CampaignHeading from './CampaignHeading'
import CampaignTags from './CampaignTags'
import CampaignDescription from './CampaignDescription'
import useCampaignAssets from '@/services/hooks/useCampaignAssets'
import SellingFast from '@/components/common/CampaignCard/SellingFast'

interface Props {
  anticipatedRegulation?: ISectionAnticipatedRegulationTypeFields
  campaign: Campaign
  className?: string
  investButtonWrapperRef?: LegacyRef<HTMLDivElement>
  sectionName: 'body' | 'sidebar' | 'hero'
  style?: CSSProperties
  theme?: 'dark' | 'light'
}

const InvestCard: React.FC<Props> = ({
  anticipatedRegulation,
  campaign,
  className,
  investButtonWrapperRef,
  sectionName,
  style = {},
  theme = 'dark',
}) => {
  const { campaign: resolvedCampaign = campaign } = useCampaign(campaign.slug)
  const { campaignAssets } = useCampaignAssets(campaign.slug)
  const siteAssets = useSiteAssets()

  const { status } = resolvedCampaign

  return (
    <div className={className} style={style}>
      <div
        id="offering_content"
        className="w-full mb-6 md:text-center lg:pt-6 2lg:pt-0 2lg:text-left"
      >
        <CampaignHeading {...campaign} theme={theme} />

        <CampaignTags
          tags={campaignAssets?.tags}
          className="mt-5 hide-scrollbar overflow-auto whitespace-nowrap 2lg:hidden"
        />

        <CampaignDescription
          document={campaignAssets?.shortDescription}
          className={classNames('mt-5', {
            'text-white': theme === 'light',
          })}
        />
      </div>

      {status !== 'testing_the_waters' && (
        <ProgressBar campaign={resolvedCampaign} theme={theme} />
      )}

      <div
        className={classNames({
          'mb-6 mt-2': true,
          'text-core-gray-950': theme === 'dark',
          'text-white': theme === 'light',
        })}
      >
        {status !== 'testing_the_waters' && (
          <AmountRaised campaign={resolvedCampaign} />
        )}

        <Backers
          campaign={resolvedCampaign}
          className={classNames({
            'mt-4': status !== 'testing_the_waters',
          })}
        />
        {status === 'currently_funding' && !siteAssets?.hideTimeRemaining && (
          <TimeRemaining campaign={resolvedCampaign} />
        )}
      </div>

      <Buffer
        campaign={resolvedCampaign}
        className={classNames({
          'my-3': true,
          'text-core-gray-950': theme === 'dark',
          'text-white': theme === 'light',
        })}
      />
      {!siteAssets?.hideTimeRemaining && (
        <SellingFast
          campaign={resolvedCampaign}
          className={classNames({
            'my-2': true,
            'text-core-gray-950': theme === 'dark',
            'text-white': theme === 'light',
          })}
        />
      )}

      {!siteAssets?.usePriorRaisesLayout && (
        <div ref={investButtonWrapperRef}>
          <InvestButton
            campaign={resolvedCampaign}
            className={classNames('block w-full mt-6', {
              '!text-core-black': theme === 'light',
            })}
            sectionName={sectionName}
            color="oxide"
          />
        </div>
      )}
      {status === 'currently_funding' && !siteAssets?.usePriorRaisesLayout && (
        <div
          className={classNames({
            'text-core-gray-950': theme === 'dark',
            'text-white': theme === 'light',
          })}
        >
          <MinInvestment
            campaign={resolvedCampaign}
            className="mt-3 text-center font-light"
          />
        </div>
      )}

      {/* TODO: check these styles with campaign that has prior raises */}
      {sectionName !== 'hero' && !siteAssets?.usePriorRaisesLayout && (
        <div className="mt-4 mb-2">
          <PriorRaise
            campaign={resolvedCampaign}
            className={classNames({
              'border-t last:border-b py-2': true,
              'text-core-gray-950 border-core-gray-200': theme === 'dark',
              'text-white border-[rgba(255,255,255,0.5)]': theme === 'light',
            })}
          />
          <InvestmentDetails
            campaign={resolvedCampaign}
            className={classNames({
              'border-t last:border-b py-2': true,
              'text-core-gray-950 border-core-gray-200': theme === 'dark',
              'text-white border-[rgba(255,255,255,0.5)]': theme === 'light',
            })}
          />
        </div>
      )}

      <CampaignDisclaimer
        className={classNames({
          'mt-6': true,
          'text-core-gray-700': theme === 'dark',
          'text-white': theme === 'light',
        })}
        campaign={resolvedCampaign}
        anticipatedRegulation={anticipatedRegulation}
      />
    </div>
  )
}

export default InvestCard

import React from 'react'
import { Type } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import { ISectionAnticipatedRegulationTypeFields } from '../../../../../types/generated/contentful'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import { Variant } from '@/components/common/Type/Type'

export const cfDisclaimer =
  '*People includes all persons who have submitted subscription materials. Amount raised is an estimate that includes subscriptions, however not all such amounts have been accepted or closed, and some subscriptions may not be accepted.'
export const previousRoundDisclaimer =
  '*People includes all persons who have submitted subscription materials for the current and prior CF rounds. Amount raised is an estimate that includes subscriptions for the current and prior CF rounds, however not all such amounts have been accepted or closed, and some subscriptions may not be accepted.'
export const ttwCFDisclaimer = `*No money or other consideration is being solicited, and if sent in response, will not be accepted. No offer to buy the securities can be accepted and no part of the purchase price can be received until the offering statement is filed and only through an intermediary's platform. A person's indication of interest involves no obligation or commitment of any kind. The expressed interest amount is a combination of the amount expressed by Angel Guild members who approved the project and individuals who have expressed interest through this page.`
export const ttwADisclaimer = `*No money or other consideration is being solicited or accepted at this time. No sales will be made or commitments to purchase accepted until the offering statement is qualified. A prospective purchaser's indication of interest in non-binding. Sales made pursuant to Regulation A are contingent upon the qualification of the offering statement.`

export interface Props {
  as?: React.ElementType
  campaign: Campaign
  anticipatedRegulation?: ISectionAnticipatedRegulationTypeFields
  className?: string
  variant?: Variant | Variant[]
}

const CampaignDisclaimer: React.FC<Props> = ({
  as = 'p',
  campaign,
  anticipatedRegulation,
  className,
  variant = 'footer',
}) => {
  const { previousRound, status } = campaign
  const siteAssets = useSiteAssets()

  if (status === 'testing_the_waters') {
    return (
      <Type as={as} variant={variant} className={className}>
        <span>
          {anticipatedRegulation?.anticipatedRegulationType === 'Reg A'
            ? ttwADisclaimer
            : ttwCFDisclaimer}
        </span>
        {anticipatedRegulation?.filedCircularLink?.fields?.url && (
          <span>
            {' '}
            The most recent preliminary offering circular can be viewed{' '}
            <a
              href={anticipatedRegulation?.filedCircularLink?.fields?.url}
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              here
            </a>
            .
          </span>
        )}
      </Type>
    )
  }

  return (
    <Type as={as} variant={variant} className={className}>
      {siteAssets?.fundingCampaignDisclaimer
        ? siteAssets?.fundingCampaignDisclaimer
        : previousRound
        ? previousRoundDisclaimer
        : cfDisclaimer}
    </Type>
  )
}

export default CampaignDisclaimer

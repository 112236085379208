import React from 'react'
import { Document } from '@contentful/rich-text-types'
import { ExpandableTextBlock } from '@/components/common'

interface Props {
  document?: Document
  className?: string
}

export const CampaignDescription: React.FC<Props> = ({
  document,
  className,
}) => {
  if (!document) return null

  return (
    <ExpandableTextBlock
      lines={3}
      className={className}
      textClassName="2lg:paragraph-sm"
      document={document}
    />
  )
}

export default CampaignDescription

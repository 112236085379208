import client from '../thinMintClient'
import { Livestream, ServerLivestream } from './types'
import { resolveLivestreamDown } from './resolver'

export const baseEndpoint = '/livestreams'

export const getLivestream = (id: string) =>
  client
    .get(`${baseEndpoint}/${id}/`)
    .then(
      (res: { data: ServerLivestream }) =>
        resolveLivestreamDown(res.data) as Livestream
    )

export const getCampaignLivestreams = (campaign: string) =>
  client
    .get(`${baseEndpoint}/?campaign=${campaign}`)
    .then(
      (res: { data: { results: ServerLivestream[] } }) =>
        res.data.results.map(resolveLivestreamDown) as Livestream[]
    )

import React, { LegacyRef } from 'react'
import { Type } from '@/components/common'
import InvestButton from './InvestButton'
import { Campaign } from '@/services/api/campaign'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import PifButton from './PifButton'
import ABTester, { ABTestProps } from '@/components/ABTester'

export interface Props {
  campaign: Campaign
  investButtonWrapperRef?: LegacyRef<HTMLDivElement>
  className?: string
  isTanButton?: boolean
}

const PriorRaisesInvestCard: React.FC<Props> = ({
  campaign,
  investButtonWrapperRef,
  className,
  isTanButton,
}) => {
  const { regulationTypeLabel, status } = campaign
  const siteAssets = useSiteAssets()

  const heading =
    status === 'currently_funding'
      ? `Currently Funding - Regulation ${regulationTypeLabel} Round`
      : `Regulation ${regulationTypeLabel} Round`
  return (
    <div className={className}>
      <div ref={investButtonWrapperRef} className="mb-4">
        <InvestButton
          campaign={campaign}
          className="block mx-auto w-full max-w-[420px]"
          sectionName="body"
          color="oxide"
          style={isTanButton ? { backgroundColor: '#C0A175' } : {}}
        />
      </div>

      <Type
        as="h3"
        variant="title-sm"
        className="mb-4 font-semibold md:text-center"
      >
        {heading}
      </Type>

      <Type as="p" variant="paragraph-md">
        {siteAssets?.priorRaisesDisclaimer}
      </Type>

      {siteAssets?.pifUrl && (
        <PifButton
          url={siteAssets.pifUrl}
          variant="secondary"
          className="block mt-4 w-full mx-auto max-w-[420px]"
        />
      )}
    </div>
  )
}

const ABPriorRaisesInvestCard: React.FC<Props> = (props) => {
  return (
    <ABTester name="david_tan_cta_button">
      {({ decision }: ABTestProps) => {
        return (
          <PriorRaisesInvestCard
            {...props}
            isTanButton={decision.variationKey === 'on'}
          />
        )
      }}
    </ABTester>
  )
}

export default ABPriorRaisesInvestCard

import { Question } from '@/components/svg'
import { useState } from 'react'
import { Text, CardModal } from '@/components/common'

const Buffer = ({ campaign, className = '' }) => {
  const [showModal, setShowModal] = useState(false)
  const showBuffer = campaign.isInBuffer

  if (!showBuffer) {
    return null
  }

  return (
    <>
      <div className={className}>
        <div
          onClick={() => setShowModal(true)}
          className="text-sm md:text-md flex-row flex cursor-pointer"
        >
          <span>Why are we still taking investments?</span>
          <Question className="w-8 md:w-auto mt-1 ml-1" />
        </div>
      </div>

      <CardModal isOpen={showModal} setIsOpen={setShowModal}>
        <CardModal.Body>
          <Text as="p" preset="body.md">
            We have reached our investment maximum. However, due to the nature
            of processing the funds, it is possible that some investments are
            never completed or are cancelled. If you commit now, even though the
            offering is full, if enough other investments drop out before
            closing, your commitment may move forward in line within the limit.
            If so, we will notify you that your investment commitment closed. If
            you do not make it into the round, we will refund your money and
            send a notice.
          </Text>
        </CardModal.Body>
      </CardModal>
    </>
  )
}

export default Buffer

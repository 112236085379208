import useApi from '@/services/hooks/useApiV2'
import {
  getUserFollowingCampaign,
  UserFollowingCampaign,
} from '@/services/api/campaign'
import useAuth from '../useAuth/useAuth'

const useFollowingCampaign = (slug: string) => {
  const { user } = useAuth()

  const { data, ...rest } = useApi<UserFollowingCampaign>({
    key: user && slug ? `user-following-campaign/${user.id}/${slug}` : false,
    request: () => getUserFollowingCampaign(slug),
  })

  return {
    isFollowing: data?.following,
    ...rest,
  }
}

export default useFollowingCampaign

import React, { useCallback } from 'react'
import { Button } from '@/components/common'
import { Checkmark } from '@/components/svg'
import {
  Campaign,
  userFollowCampaign,
  userUnfollowCampaign,
} from '@/services/api/campaign'
import useAuth from '@/services/hooks/useAuth'
import { Props as ButtonProps } from '@/components/common/Button/Button'
import useFollowingCampaign from '@/services/hooks/useFollowingCampaign/useFollowingCampaign'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import useABTest from '@/services/hooks/useABTest'

export interface Props extends Omit<ButtonProps, 'color'> {
  campaign: Campaign
  onClickCallback?: () => void
  color: 'oxide' | 'copper'
}

const FollowButton = ({ campaign, onClickCallback, ...rest }: Props) => {
  const { slug } = campaign
  const { user, openModal, startAuth } = useAuth()
  const { isFollowing, mutate } = useFollowingCampaign(slug)
  const [decision] = useABTest('ellis_island_oauth2')

  const handleClick = useCallback(async () => {
    if (!user) {
      if (decision.variationKey === 'on') {
        startAuth()
      } else {
        openModal('login')
      }
      return
    }

    if (isFollowing) {
      await userUnfollowCampaign(slug)
      SegmentHandler.track('User Unsubscribed', {
        project: slug,
      })
    } else {
      await userFollowCampaign(slug)
      SegmentHandler.track('User Subscribed', {
        project: slug,
      })
    }

    await mutate()

    onClickCallback?.()
  }, [
    isFollowing,
    slug,
    user,
    openModal,
    mutate,
    onClickCallback,
    startAuth,
    decision.variationKey,
  ])

  return (
    <Button
      onClick={handleClick}
      variant={isFollowing ? 'secondary' : 'primary'}
      {...rest}
    >
      <span className="flex items-center justify-center">
        {isFollowing && <Checkmark className="w-5 h-5 mr-2" />}
        <span>
          {isFollowing ? 'Subscribed to Updates' : 'Subscribe to Updates'}
        </span>
      </span>
    </Button>
  )
}

export default FollowButton
